<div class="main-content body-bg listing-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-4">
      <div>
        <h4 class="mat-headline-5 strong text mb-1">Invoicing</h4>
        <sft-button
          [label]="
            'Manage Your Invoice
          '
          "
          [color]="'primary'"
          className="grey-text-btn textXS p-0"
          [variant]="'default'"
          [disable]="false"
          [withIcon]="true"
          [icon]="'keyboard_backspace'"
          [disableRipple]="false"
          [size]="'small'"
          (buttonClick)="goBack()"
        ></sft-button>
      </div>
      <div>
        <sft-button
          [label]="'Downloads'"
          [color]="'primary'"
          [variant]="'flat'"
          [icon]="'home'"
          [size]="'large'"
          (buttonClick)="generatePDF()"
        ></sft-button>
      </div>
    </div>

    <div class="body-bg listing-content overflow-auto craft-listing">
      <app-loader *ngIf="loading"></app-loader>
      <ng-container *ngIf="!loading">
        <div
          class="invoice-box"
          style="
            max-width: 1268px;

            background-color: #ffffff;
            margin: 0px auto;
            border: 1px solid #ccc;
            padding: 24px;
          "
        >
          <table style="margin-bottom: 16px; width: 100%">
            <tr>
              <td
                colspan="2"
                style="border: 1px solid #c5d4db; padding: 8px 16px"
              >
                <table style="width: 100%">
                  <tr>
                    <td style="min-width: 103px; width: 103px">
                      <img src="/images/invoicelogo.svg" />
                    </td>
                    <td style="text-align: right">
                      <div style="margin-left: 24px">
                        <span
                          style="
                            text-align: left;
                            margin-bottom: 5px;
                            text-transform: uppercase;
                            display: block;
                            font-size: 21px;
                            color: #1d1d1d;
                            font-weight: 600;
                          "
                          >Softobiz Technologies PVT LTD - INDIA</span
                        >
                        <span
                          style="
                            display: block;
                            text-align: left;
                            font-size: 12px;
                          "
                        >
                          SEBIZ SQUARE, IT PARK SECTOR -67. MOHALI, PUNJAB
                          MOHALI PUNJAB 160062</span
                        >
                      </div>
                    </td>
                    <td
                      class="text-end"
                      style="font-size: 24px; font-weight: 500; color: #1d1d1d"
                    >
                      TAX INVOICE
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table
            style="
              border: 1px solid #c5d4db;
              padding: 8px;
              width: 100%;
              margin-bottom: 16px;
            "
          >
            <table style="width: 50%; border-right: 1px solid #c5d4db">
              <tr>
                <td
                  style="
                    width: 75px;
                    color: #414855;
                    font-size: 12px;
                    padding-right: 16px;
                  "
                >
                  #
                </td>
                <td style="color: #1d1d1d; font-size: 12px">
                  <span style="margin-right: 16px">:</span
                  >{{ invoicingItemData?.invoiceDate | date : "yyyyMMdd" }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    width: 81px;
                    color: #414855;
                    font-size: 12px;
                    padding-right: 16px;
                  "
                >
                  Invoice Date
                </td>
                <td style="color: #1d1d1d; font-size: 12px">
                  <span style="margin-right: 16px">:</span
                  >{{ invoicingItemData?.invoiceDate | date : "dd/MM/yyyy" }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    width: 75px;
                    color: #414855;
                    font-size: 12px;
                    padding-right: 16px;
                  "
                >
                  Terms
                </td>
                <td style="color: #1d1d1d; font-size: 12px">
                  <span style="margin-right: 16px">:</span>Net 30
                </td>
              </tr>
              <tr>
                <td
                  style="
                    width: 75px;
                    color: #414855;
                    font-size: 12px;
                    padding-right: 16px;
                  "
                >
                  Due Date
                </td>
                <td style="color: #1d1d1d; font-size: 12px">
                  <span style="margin-right: 16px">:</span
                  >{{ invoicingItemData?.dueDate | date : "dd/MM/yyyy" }}
                </td>
              </tr>
            </table>
          </table>
          <table
            style="
              padding: 4px;
              border: 1px solid #c5d4db;
              width: 100%;
              margin-bottom: 16px;
            "
          >
            <tr>
              <th
                style="
                  font-size: 12px;
                  font-weight: 400;
                  color: #1d1d1d;
                  text-align: left;
                  background-color: #f5fbff;
                  padding-left: 4px;
                  padding-right: 4px;
                "
              >
                Bill to
              </th>
            </tr>
            <tr>
              <td style="font-size: 12px; color: #1d1d1d">
                {{ invoicingItemData?.account?.name }}
                {{ invoicingItemData?.account?.address[0]?.fullAddress }}
                {{ invoicingItemData?.account?.address[0]?.city }}
                {{ invoicingItemData?.account?.address[0]?.state }}
                {{ invoicingItemData?.account?.address[0]?.zipCode }}
                {{ invoicingItemData?.account?.address[0]?.country }}
              </td>
            </tr>
          </table>
          <table style="width: 100%; margin-bottom: 16px">
            <tr>
              <th
                style="
                  font-size: 12px;
                  color: #414855;
                  text-align: left;
                  font-weight: 400;
                  padding-bottom: 8px;
                  display: block;
                "
              >
                Subject
              </th>
            </tr>
            <tr>
              <td style="font-size: 12px; color: #1d1d1d">
                {{ invoicingItemData?.subject }}
              </td>
            </tr>
          </table>
          <table style="width: 100%; border-collapse: collapse">
            <tr style="background-color: #f5fbff">
              <th
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  text-align: left;
                  font-size: 13px;
                  color: #1d1d1d;
                  font-weight: 400;
                  padding: 8px 12px;
                  width: 43px;
                "
              >
                Sno.
              </th>
              <th
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  text-align: left;
                  font-size: 13px;
                  color: #1d1d1d;
                  font-weight: 400;
                  padding: 8px 12px;
                  width: 726px;
                "
              >
                Item & Description
              </th>
              <th
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  min-width: 135px;
                  text-align: left;
                  font-size: 13px;
                  color: #1d1d1d;
                  font-weight: 400;
                  padding: 8px 12px;
                "
              >
                Qty
              </th>
              <th
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  min-width: 65px;
                  text-align: left;
                  font-size: 13px;
                  color: #1d1d1d;
                  font-weight: 400;
                  padding: 8px 12px;
                "
              >
                Rate ({{ invoicingItemData?.account?.financials[0]?.currency }})
              </th>
              <th
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  min-width: 65px;
                  text-align: right;
                  font-size: 13px;
                  color: #1d1d1d;
                  font-weight: 400;
                  padding: 8px 12px;
                "
              >
                Amount ({{
                  invoicingItemData?.account?.financials[0]?.currency
                }})
              </th>
            </tr>
            <tr
              *ngFor="
                let item of invoicingItemData?.invoiceItems;
                let i = index
              "
            >
              <td
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  padding: 4px 12px;
                  font-size: 13px;
                  color: #1d1d1d;
                  font-weight: 300;
                  cursor: pointer;
                "
              >
                {{ i + 1 }}
              </td>
              <td
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  padding: 4px 12px;
                  font-size: 13px;
                  color: #1d1d1d;
                  font-weight: 400;
                  cursor: pointer;
                "
              >
                {{ item.description }}
              </td>
              <td
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  padding: 4px 12px;
                  font-size: 14px;
                  color: #1d1d1d;
                  cursor: pointer;
                "
              >
                {{ item.quantity }}
              </td>
              <td
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  padding: 4px 12px;
                  font-size: 14px;
                  color: #1d1d1d;
                  font-weight: 300;
                  cursor: pointer;
                "
              >
                {{
                  getCurrencySymbol(
                    invoicingItemData?.account?.financials[0]?.currency
                  )
                }}
                {{ item.rate }}
              </td>
              <td
                style="
                  border: 1px solid rgb(0 0 0 / 50%);
                  text-align: end;
                  padding: 4px 12px;
                  font-size: 14px;
                  color: #1d1d1d;
                  font-weight: 300;
                  cursor: pointer;
                "
              >
                {{
                  getCurrencySymbol(
                    invoicingItemData?.account?.financials[0]?.currency
                  )
                }}{{ item.amount }}
              </td>
            </tr>
            <tr style="height: 60px">
              <td
                colspan="3"
                style="
                  text-align: left;
                  color: #000000;
                  font-size: 12px;
                  padding-top: 8px;
                "
              >
                Total In Words
                <span
                  style="font-style: italic; font-weight: bold; font-size: 12px"
                >
                  <br />
                  {{ totalInWords }}
                </span>
              </td>
              <td
                style="
                  text-align: right;
                  text-align: left;
                  color: #1d1d1d;
                  font-size: 13px;
                  padding: 4px 12px;
                "
              >
                Subtotal:
              </td>
              <td
                style="
                  text-align: right;
                  color: #1d1d1d;
                  font-size: 13px;
                  color: #1d1d1d;
                  font-size: 13px;
                  font-weight: 400;
                  padding: 4px 12px;
                "
              >
                {{
                  getCurrencySymbol(
                    invoicingItemData?.account?.financials[0]?.currency
                  )
                }}{{ invoicingItemData?.subTotal }}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                style="text-align: left; color: #000000; font-size: 12px"
              >
                Thanks for your business.
              </td>
              <td
                style="
                  text-align: right;
                  font-weight: 500;
                  color: #1d1d1d;
                  font-size: 13px;
                  text-align: left;
                  padding: 8px 12px;
                  border: 1px solid #c5d4db;
                  min-width: 95px;
                "
              >
                Total
              </td>
              <td
                style="
                  text-align: right;
                  font-weight: 500;
                  color: #1d1d1d;
                  font-size: 13px;
                  border: 1px solid #c5d4db;
                  padding: 8px 12px;
                  color: #1d1d1d;
                  font-size: 13px;
                  min-width: 95px;
                "
              >
                {{ invoicingItemData?.account?.financials[0]?.currency }}
                {{ invoicingItemData?.total }}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                style="text-align: left; color: #000000; font-size: 12px"
              ></td>
              <td
                style="
                  text-align: right;
                  padding: 8px 12px;
                  font-weight: 500;
                  color: #1d1d1d;
                  font-size: 13px;
                  text-align: left;
                  border: 1px solid #c5d4db;
                  min-width: 95px;
                "
              >
                Balance Due
              </td>
              <td
                style="
                  text-align: right;
                  padding: 8px 12px;
                  font-weight: 500;
                  color: #1d1d1d;
                  font-size: 13px;
                  border: 1px solid #c5d4db;
                  color: #1d1d1d;
                  font-size: 13px;
                  min-width: 95px;
                "
              >
                {{ invoicingItemData?.account?.financials[0]?.currency }}
                0
              </td>
            </tr>
          </table>
          <table style="padding-bottom: 16px">
            <tr>
              <th
                style="
                  font-size: 13px;
                  color: #000000;
                  text-align: left;
                  font-weight: 500;
                  display: block;
                "
              >
                Bank Account Details:
              </th>
              <td style="display: block; color: #414855; font-size: 11px">
                Account Name: {{ bankDetails?.accountName }}
              </td>
              <td style="display: block; color: #414855; font-size: 11px">
                Account Number: {{ bankDetails?.accountNumber }}
              </td>
              <td style="display: block; color: #414855; font-size: 11px">
                Bank Name: {{ bankDetails?.bankName }}
              </td>
              <td style="display: block; color: #414855; font-size: 11px">
                Address: {{ bankDetails?.address }}
              </td>
              <td style="display: block; color: #414855; font-size: 11px">
                Swift Code: {{ bankDetails?.codeSwift }}
              </td>
              <td style="display: block; color: #414855; font-size: 11px">
                IFSC Code: {{ bankDetails?.codeIFSC }}
              </td>
            </tr>
          </table>
          <table style="width: 100%; margin-bottom: 106px">
            <th style="font-size: 12px; color: #000000; font-weight: 400">
              This invoice is computer-generated and does not necessitate a
              signature.
            </th>
          </table>
        </div>
      </ng-container>
    </div>
  </div>
</div>
