import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDrawerMode } from '@angular/material/sidenav';
import { debounceTime, distinctUntilChanged, map, Observable, tap } from 'rxjs';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { InvoicingService } from 'src/app/admin-portal/modules/invoicing/services/invoicing.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';

@Component({
  selector: 'app-invoicing-dialog-filter',
  templateUrl: './filter-invoicing-sidebar.component.html',
})
export class FilterInvoicingSidebarComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  accountID: string = '';
  items = ['Invoice Statement', 'Project Name', 'Cost Center'];
  expandedIndex = 0;
  costCenters: any[] = [];
  invoiceStatus: any[] = [];
  projects: any[] = [];
  selectedDates: string[] = [];
  selectedProjects: string[] = [];
  selectedCostCenters: string[] = [];
  invoiceDate: any;
  loading: boolean = false;
  filteredProjects: any[] = [];
  search: string = '';
  form!: FormGroup;

  selectedDatesMap: { [key: string]: boolean } = {
    two: false,
    three: false,
    four: false,
    five: false,
  };
  selectedProjectsMap: { [key: string]: boolean } = {};
  selectedCostCentersMap: { [key: string]: boolean } = {};

  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private costCenterService: CostCenterService,
    private projectService: ProjectService,
    private invoicingService: InvoicingService,
    private formbuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formbuilder.group({
      searchQuery: [''],
    });

    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((value) => (this.search = value)),
        map((value) => this.searchProjects(value))
      )
      .subscribe();

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.loadCostCenters().subscribe();
    this.loadProjects().subscribe();
  }

  searchProjects(searchValue: string) {
    if (!searchValue) {
      this.filteredProjects = this.projects;
    } else {
      searchValue = searchValue.toLowerCase();
      this.filteredProjects = this.projects.filter((project) =>
        project.name.toLowerCase().includes(searchValue)
      );
    }
  }

  loadProjects(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.projectService
      .getProjectListCustomer(
        0,
        MAX_LIMIT,
        '',
        'createdAt',
        'ASC',
        '',
        this.accountID
      )
      .pipe(
        tap((response: any) => {
          this.projects = response.data.records;
          this.filteredProjects = this.projects;
        })
      );
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostListCustomer(0, MAX_LIMIT, '', 'createdAt', 'ASC', this.accountID)
      .pipe(
        tap((response: any) => {
          this.costCenters = response.data.records;
        })
      );
  }


  hasSelectedFilters(): boolean {
    return this.selectedCostCenters.length > 0 || this.selectedProjects.length > 0 || this.selectedDates.length > 0;
  }

  applyFilters(): void {
    if (this.hasSelectedFilters()) {
      this.filtersApplied.emit({
        selectedDates: this.selectedDates,
        selectedProjects: this.selectedProjects,
        selectedCostCenters: this.selectedCostCenters,
      });
      this.isOpen = false;
    }
  }

  clearFilters(): void {
    this.selectedDates = [];
    this.selectedCostCenters = [];
    this.selectedProjects = [];
    this.selectedDatesMap = {
      two: false,
      three: false,
      four: false,
      five: false,
    };
    this.selectedProjectsMap = {};
    this.selectedCostCentersMap = {};
    this.applyFilters();
    this.filtersApplied.emit({});
  }

  handleFilterChange(checked: any, month: string) {
    let startDate: Date;
    this.loading = true;

    switch (month) {
      case 'one':
        this.invoiceDate = '';
        break;
      case 'two':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 3);
        this.invoiceDate = this.formatDate(startDate);
        break;
      case 'three':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 6);
        this.invoiceDate = this.formatDate(startDate);
        break;
      case 'four':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 9);
        this.invoiceDate = this.formatDate(startDate);
        break;
      case 'five':
        this.invoiceDate = '2023-01-01';
        break;
      default:
        break;
    }

    this.selectedDates = [this.invoiceDate];
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  onDateChange(checked: boolean, date: string): void {
    if (checked && !this.selectedDates.includes(date)) {
      this.selectedDates.push(date);
    } else if (!checked && this.selectedDates.includes(date)) {
      const index = this.selectedDates.indexOf(date);
      if (index > -1) {
        this.selectedDates.splice(index, 1);
      }
    }
  }

  onProjectChange(event: any, project: any): void {
    if (event.checked) {
      this.selectedProjects.push(project.projectId);
    } else {
      const index = this.selectedProjects.indexOf(project.projectId);
      if (index > -1) {
        this.selectedProjects.splice(index, 1);
      }
    }
  }

  onCostCenterChange(event: any, center: any): void {
    if (event.checked) {
      this.selectedCostCenters.push(center.cc_cost_centerID);
    } else {
      const index = this.selectedCostCenters.indexOf(center.cc_cost_centerID);
      if (index > -1) {
        this.selectedCostCenters.splice(index, 1);
      }
    }
  }


  toggleProjectCheckbox(event: any, project: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleCostCenterCheckbox(event: any, center: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }
}
