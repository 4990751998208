import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerInvoicingComponent } from './components/invoicing/invoicing.component';
import { CustomerInvoicingPreviewComponent } from './components/invoicing-preview/invoicing-preview.component';

const routes: Routes = [
  {
    path: '',
    component: CustomerInvoicingComponent,
  },
  {
    path: 'view/:id',
    component: CustomerInvoicingPreviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerInvoicingRoutingModule {}
