import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { takeUntil } from 'rxjs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CustomerInvoicingService } from '../../services/invoicing.service';
import {
  convertCurrencyToWords,
  convertNumberToWords,
} from 'src/app/utils/number-to-words.util';

@Component({
  selector: 'app-invoicing-preview',
  templateUrl: './invoicing-preview.component.html',
})
export class CustomerInvoicingPreviewComponent implements OnInit {
  id!: string;
  isSidebarOpen = false;
  isSidepanelOpen = false;
  isDrawerOpen = false;
  drawerWidth = 200;
  isActive = false;
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  invoicingItemData: any = {};
  loading: boolean = false;
  totalInWords: string = '';
  bankDetails: any = {};
  canManageResources: boolean = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private invoicingService: CustomerInvoicingService,
    private globalService: GlobalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const cost_centerPermissions = userPermissions.find(
        (p: any) => p.module === 'invoice'
      );
      if (
        cost_centerPermissions &&
        cost_centerPermissions.permission.includes('view') &&
        cost_centerPermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      }
    }

    this.id = this.route.snapshot.params['id'];
    this.globalService.setInvoiceId(this.id);
    this.getInvoicingItemData();
    this.getBankDetail();
  }

  generatePDF() {
    const element = document.querySelector(
      '.invoice-box'
    ) as HTMLElement | null;
    if (element) {
      const scale = 2;
      const options = {
        scale: scale,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      };

      html2canvas(element, options).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('invoice.pdf');
      });
    } else {
      console.error('Element not found');
    }
  }

  getInvoicingItemData(): void {
    this.loading = true;
    this.invoicingService
      .getInvoiceItemListById(this.id)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingItemData = data?.data;
          this.loading = false;
          this.totalInWords =
            convertCurrencyToWords(
              this.invoicingItemData?.account?.financials[0]?.currency
            ) +
            ' ' +
            convertNumberToWords(
              this.invoicingItemData.total,
              this.invoicingItemData?.account?.financials[0]?.currency
            );
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getBankDetail(): void {
    this.loading = true;
    this.invoicingService
      .getBankDetails()
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.bankDetails = data?.data;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in fetching Bank Details:', error);
          this.loading = false;
        },
      });
  }

  goBack() {
    this.router.navigateByUrl('customer/invoicing');
  }

  getCurrencySymbol(currencyCode: string): string {
    switch (currencyCode) {
      case 'INR':
        return '₹';
      case 'USD':
        return '$';
      case 'AUD':
        return '$';
      default:
        return '';
    }
  }
}
