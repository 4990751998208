import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerInvoicingService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiService: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  getInvoiceList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    accountId?: string,
    invoiceDate?: string,
    projectId?: string,
    cost_centerID?: string,
    invoiceStatus?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/customer/invoice/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountId) {
      path += `&accountID=${accountId}`;
    }

    if (invoiceDate) {
      path += `&invoiceDate=${invoiceDate}`;
    }

    if (projectId) {
      path += `&projectId=${projectId}`;
    }

    if (cost_centerID) {
      path += `&cost_centerID=${cost_centerID}`;
    }

    if (invoiceStatus) {
      path += `&invoiceStatus=${invoiceStatus}`;
    }

    return this.apiService.get(path);
  }

  getInvoiceItemListById(invoiceID: string): Observable<any> {
    const path = `${this.baseUrl}/customer/invoice/${invoiceID}`;
    return this.apiService.get(path);
  }

  getBankDetails(): Observable<any> {
    const path = `${this.baseUrl}/bank-detail`;
    return this.apiService.get(path);
  }
}
