import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { CustomerLayoutModule } from '../../core/layout/layout.module';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { CustomerInvoicingRoutingModule } from './invoicing-routing.module';
import { CustomerInvoicingComponent } from './components/invoicing/invoicing.component';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { CustomerInvoicingPreviewComponent } from './components/invoicing-preview/invoicing-preview.component';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterInvoicingSidebarComponent } from './components/invoicing/filter-sidebar/filter-invoicing-sidebar.component';

@NgModule({
  declarations: [
    CustomerInvoicingComponent,
    CustomerInvoicingPreviewComponent,
    FilterInvoicingSidebarComponent,
  ],
  imports: [
    CustomerLayoutModule,
    CommonModule,
    SidebarModule,
    SftButtonModule,
    CustomerInvoicingRoutingModule,
    AngularMaterialModule,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class CustomerInvoicingModule {}
